

import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import supplierType from "@/core/data/supplierTypetypes";
import {Actions} from "@/store/enums/StoreEnums";
import {ErrorMessage, Field, Form} from "vee-validate";
import * as Yup from "yup";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";


interface IformVals {
  Title: string;
  Type: string;
  Country: string;
  City: string;
  Adress: string;
}

export default defineComponent({
  name: "add-new-supplier",
  components: {
    ErrorMessage,
    Field,
    Form,
  },

  setup() {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);

    const IformVals = ref<IformVals>({
      Title: "",
      Type: "",
      Country: "",
      City: "",
      Adress: "",
    });


    const validationSchema = Yup.object().shape({
      Title: Yup.string().required("Tedarikçi Adı girilmemiş"),
      Type: Yup.string().required("Tedarikçi Tipi seçilmemiş"),
      Country: Yup.string().required("Tedarikçi Ülkesi seçilmemiş"),
      City: Yup.string().required("Tedarikçi Şehri seçilmemiş"),
    });


    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      const payloadSupplier = {
        Title: IformVals.value.Title,
        Type: IformVals.value.Type,
        Country: IformVals.value.Country,
        City: IformVals.value.City,
        Address: IformVals.value.Adress,
        Phone: "",
        Email: "",
        OperationEmail: "",
        OperationPhone: "",
        AccountingEmail: "",
        AccountingPhone: "",
        TaxOffice: "",
        TaxNumber: "",
        is_active: "active",
      }


      store.dispatch(Actions.SUPPLIERS_INSERT, payloadSupplier)
          .then(() => {
            store.dispatch(Actions.SUPPLIERS_LIST_ALL, {});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(modalRef.value);
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getSuppliersErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    }


    store.dispatch(Actions.CITY_LIST, {});
    const myCities = computed(() => {
      return store.getters.CityList;
    });
    const filteredCity = computed(() => {
      if (myCities.value.data != null) {
        return (myCities.value.data).filter(citiesItem =>
            citiesItem.Country == (IformVals.value.Country).toLowerCase()
        );
      } else {
        return true;
      }
    });

    store.dispatch(Actions.SUPPLIERS_LIST, {});
    const mySupplier = computed(() => {
      return store.getters.suppliersList;
    });


    return {
      IformVals,
      supplierType,
      filteredCity,
      validationSchema,
      submitButtonRef, modalRef,
      submit,
      mySupplier,
    }
  },

});
